<template>
  <div class="join">
    <my-swiper :bannerList="bannerList" :pagination="false"></my-swiper>
    <div
      class="yxj-container"
      style="overflow: visible"
    >
      <div class="join-container page-container">
        <div class="qr-area">
          <div class="qr-center mobile-qr box"  v-for="(item, index) in pageObj" :key="index">
            <div class="wx-tips">{{item.title}}</div>
            <div class="wx-label">
              <p v-html="item.info"></p>
            </div>
          </div>
        </div>
        <div class="area-center">
          <div class="area-box">
            <p class="area-name area-name-bottom"><span>热招</span>职位</p>
            <div class="recruit-wrapper">
              <a
                class="cheng"
                target="_blank"
                href="https://jobs.51job.com/all/co221968.html"
              ></a>
              <a
                class="boss"
                target="_blank"
                href="https://www.zhipin.com/gongsi/e8f11e0b360496371XF-3di0.html?ka=search_rcmd_company_name_e8f11e0b360496371XF-3di0_custompage "
              ></a>
              <a
                class="cooperated"
                target="_blank"
                href="https://www.liepin.com/company/8429999/"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Join',
  data () {
    return {
      pageObj: [
        {
          title: '邀请优秀的你，加入我们！',
          info: '如果你热爱循证医学，<br />如果你愿意“服务医者 改善医疗”，<br />医学界，<br />是你绝佳的选择！',
          mobileInfo: '如果你热爱循证医学，如果你愿意“服务医者 改善医疗”，医学界，是你绝佳的选择！'
        },
        {
          title: '做对的、难的、需要时间积累的事儿！',
          info: '坚守初心，稳步向前，<br />不断积累与沉淀，<br />共同推动中国医疗事业的进步，<br />我们期待，与你志同道合！',
          mobileInfo: '坚守初心，稳步向前，不断积累与沉淀，共同推动中国医疗事业的进步，我们期待，与你志同道合！'
        },
        {
          title: '加入我们，你将获得：',
          info: '舒适的工作环境<br />完善的薪资福利<br />快速的成长晋升<br />丰厚的期权回报',
          mobileInfo: '舒适的工作环境完善的薪资福利快速的成长晋升丰厚的期权回报'
        }
      ],
      bannerList: [
        {
          to: '/recruitment',
          img: require('@/assets/images/banner16.jpg'),
          mobileImg: require('@/assets/images/banner16m.png'),
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
